// fonts + icons + global css

@import '../../resources/fonts/Akkurat.scss';
@import '../../resources/fonts/AkkuratMono.scss';
@import '../../resources/fonts/MaterialIcons.scss';

@import '../../resources/sass/mixins/all';
@import '../../resources/sass/settings/all';

html, body {
	font-family: "Akkurat", sans-serif;
}

// scrollbars

::-webkit-scrollbar { 
//    display: none; 
}

// app wrapper

#root {
  
  /*
    position: fixed;
    overflow: hidden;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    
  */  
}

// editor

.admin-editor {
	background-color: white;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	
	overflow: hidden;
}